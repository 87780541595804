import React , {useEffect} from 'react';

import { AppTopMenu } from 'components/AppDashboard/AppTopMenu/AppTopMenu';
import { AppSideMenu } from 'components/AppDashboard/AppSideMenu/AppSideMenu';

import { AppDiv } from 'components/AppDiv/AppDiv';
import { AppFooter } from 'components/AppFooter/AppFooter';
import { useNavigate  } from 'react-router-dom';
import "./LoggedInLayout.css";
/**
 * MainLayout
*/
export default function LoggedInLayout(props) {


  const navigate = useNavigate();
  
  useEffect(() => {
        if ( localStorage.getItem("app-token") == null ) {
            navigate("/login");
        }
    }, []);
  return (
    <main className={  'app-LoggedIn-component app-user-is-logged-in app-page app-' + props.cssClass} >
      
      {/* <AppSideMenu /> */}
      <AppDiv cssClass={'app-dashboard-content'}>
        <AppTopMenu />
        <AppDiv cssClass={'app-dashboard-content-inner'}>
          {props.children} 
        </AppDiv>
      </AppDiv>
    </main>
  );
}