import React , { useEffect, useState } from 'react';
import LoggedInLayout from 'layouts/LoggedInLayout'
import { useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { AppSection } from 'components/AppSection/AppSection';
import { AppContainer } from 'components/AppContainer/AppContainer';
import { AppRow } from 'components/AppRow/AppRow';
import { AppCol } from 'components/AppCol/AppCol';
import { AppLoggedInTitle } from 'components/AppDashboard/AppLoggedInTitle';
import { AppBox } from 'components/AppDashboard/AppBox';
import './page.css';
import  axiosOptions from "api/project/axios/axios";
import axios from 'axios';  
import { getDataFromStorage, cleanAll } from '../../../localStorage/localStorage'
import { FaFileAlt, FaDownload } from 'react-icons/fa'; // Import icons from react-icons
import { PDFDocument, StandardFonts } from 'pdf-lib';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import fontkit from '@pdf-lib/fontkit';

const FileItem = ({ file, onDownload,  }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #e1e4e8',
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    width: '500px'
  }} 
  onMouseOver={e => e.currentTarget.style.backgroundColor = '#f6f8fa'}
  onMouseOut={e => e.currentTarget.style.backgroundColor = 'white'}
  onClick={() => downloadDocument(file.token,file.name,onDownload, file.type)}>
    <span style={{ display: 'flex', alignItems: 'center'  }}>
      <FaFileAlt style={{ marginRight: '10px' }} />
    
    </span>
    {file.name}
  </div>
);

const FileList = ({ files, onDownload }) => (
  <div style={{
    border: '1px solid #e1e4e8',
    borderRadius: '5px',
    width: 'fit-content',
    margin: '20px auto',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  }}>
    {files.map(file => (
      <FileItem key={file.id} file={file} onDownload={onDownload} />
    ))}
  </div>
);
function  Page() {
  const { t , i18n} = useTranslation();
  const tokens = getDataFromStorage('tokens')
  const [isLoading, setIsLoading] = useState(false);
  console.log(`tokens ${tokens}`)
  const files = JSON.parse(tokens)

  
  return(
    <LoggedInLayout  cssClass={'page-bitspace'}>
      <div style ={{width: '100%', display: 'flex',  justifyContent:'center'}}>
        תוכן מייל מאובטח
      </div>
        <AppSection removePadding={'both'}>
        <AppContainer>
        {isLoading && <div className="loader">
          </div>}
         <FileList files={files} onDownload={setIsLoading}/>
        </AppContainer>
      </AppSection>
      

    
    </LoggedInLayout>
  )
} 


async function downloadDocument(documentToken, documentName, setIsLoadingDocument, documentType) {

    
  const url = `https://8b6oy2kgs9.execute-api.eu-west-1.amazonaws.com/dev/secureStorage/${documentToken}`;
  const fileToken =getDataFromStorage('app-token')
  try {
      const headers = {
          'Authorization': `Bearer ${fileToken}`,
          'download-type':'base64',
          'transaction-id': uuidv4()
      }
      console.log(`documentName ${documentName} documentType ${documentType}`)

      setIsLoadingDocument(true)
      const response = await fetch(url, {
          method: 'GET',
          headers: headers
      });

      setIsLoadingDocument(false)
      if (!response.ok) {
          throw new Error('Network response was not ok.');
      }

     

      
      if(documentType && documentType.indexOf('csv') > -1){
          const textReposne =  await response.text()
          const fontResponse = await fetch('http://localhost:3000/assets/font/nehama.ttf'); // Adjust the font file path
          const fontBytes = await fontResponse.arrayBuffer();
          createAndOpenPdfFromText(textReposne, fontBytes);
      }else{
          const blob = await response.blob();
          const localUrl = URL.createObjectURL(blob);
          window.open(localUrl, '_blank');

      }
      
      // setFileUrl(localUrl);
      // Open the file in a new tab
      // const downloadUrl = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = downloadUrl;
      // a.download = documentName; // or use a dynamic name based on the document
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(downloadUrl);
      // document.body.removeChild(a);
  } catch (error) {
      setIsLoadingDocument(false)
      console.error('Error during fetch:', error);
  }
}


const createAndOpenPdfFromText = async (text, fontBytes) => {
  const doc = await PDFDocument.create();
  doc.registerFontkit(fontkit); // Register fontkit with the PDF document

  // Embed the font
  const customFont = await doc.embedFont(fontBytes);

  Papa.parse(text, {
    header: true,
    skipEmptyLines: true,
    complete: async function(results) {
      const page = doc.addPage();
      const fontSize = 10; // Set the font size to 10 or any other desired size

      // Add headers
      if (results.data.length > 0) {
        let headers = Object.keys(results.data[0]);
        headers.forEach((header, idx) => {
          page.drawText(reverseHebrewText(header), {
            x: 50 + (idx * 100),
            y: page.getHeight() - 30,
            size: fontSize,
            font: customFont,
          });
        });
      }

      // Add data below headers
      results.data.forEach((row, index) => {
        let yPos = page.getHeight() - 50 - (index * (fontSize + 10)); // Adjust Y position based on font size
        Object.values(row).forEach((value, idx) => {
          page.drawText(reverseHebrewText(value), {
            x: 50 + (idx * 100),
            y: yPos,
            size: fontSize,
            font: customFont,
          });
        });
      });

      const pdfBytes = await doc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  });
};

function reverseHebrewText(text) {
  const hebrewCharPattern = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
  return text.split(/(\d+|\s+)/).map((segment) => {
    // Reverse the segment if it contains a Hebrew character, otherwise return as is
    return hebrewCharPattern.test(segment) ? segment.split('').reverse().join('') : segment;
  }).join('');
}

export default Page