


const axiosOptions = {
  baseURL: "https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers" ,
  headers: {
  	'content-type': 'application/json' 
  }
};



if ( process.env.REACT_APP_ENV_MODE === 'tst' ) {
  axiosOptions.baseURL = 'https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers';
} else if ( process.env.REACT_APP_ENV_MODE === 'prd' ) {
  axiosOptions.baseURL =  'https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers';
}
else if ( process.env.REACT_APP_ENV_MODE === 'stg' ) {
  axiosOptions.baseURL =  'https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers';;
}







export default axiosOptions  