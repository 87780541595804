import {AppDiv} from '../AppDiv/AppDiv';

import "./AppCol.css";
/**
  * App Col component
*/
export const AppCol = ( {cssClass , textAlign , alignItems , children , justifycontent = 'center'} ) => {

    const cssClasses = [];
    cssClasses.push('app-flex');
    cssClasses.push('app-col-component');
    
    if ( textAlign ) {
      cssClasses.push('app-text-align-' + textAlign);
    }

    if ( alignItems ) {
      cssClasses.push('app-alignitems-' + alignItems);
    }

    if ( justifycontent ) {
      cssClasses.push('app-justify-' + justifycontent);
    }

    if ( cssClass ) {
      cssClasses.push(cssClass);
    }
    
    return (
      <AppDiv cssClass={cssClasses.join(" ")}>
        <AppDiv cssClass={'app-col-inner-component'}>
         {children}
        </AppDiv>
      </AppDiv>
    )
  }
  