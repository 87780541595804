import axios from 'axios';
import { getDataFromStorage } from 'localStorage/localStorage'

const axiosOptions = {
  baseURL: "https://aa6kkl672f.execute-api.eu-west-1.amazonaws.com/dev/users" ,
  headers: {
  	'content-type': 'application/json' 
  }
};

if ( process.env.REACT_APP_ENV_MODE === 'tst' ) {
  axiosOptions.baseURL = 'https://aa6kkl672f.execute-api.eu-west-1.amazonaws.com/dev/users';
} else if ( process.env.REACT_APP_ENV_MODE === 'prd' ) {
  axiosOptions.baseURL = 'https://aa6kkl672f.execute-api.eu-west-1.amazonaws.com/dev/users';
}

else if ( process.env.REACT_APP_ENV_MODE === 'stg' ) {
  axiosOptions.baseURL = 'https://aa6kkl672f.execute-api.eu-west-1.amazonaws.com/dev/users';
}

if ( getDataFromStorage('app-token') ) {
  axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
}



var  Http = axios.create(axiosOptions);

export default Http  