import { AppDiv } from "components/AppDiv/AppDiv";
import { Link } from "react-router-dom";
import  "./AppButton.css";
export const AppButton = ({
    type = 'button', 
    to = '',  
    textAlign,
    alignItems, 
    cssClass,
    handleOnClick,
    children,
    transparent = false,
    disabled= false,
    href = ''
}) => {


  const cssClasses = [];
  cssClasses.push('app-button-component-wrapper');
  if ( textAlign ) {
    cssClasses.push('app-text-align-' + textAlign);
  }

  if ( alignItems ) {
    cssClasses.push('app-alignitems-' + alignItems);
  }

  if ( transparent ) {
    cssClasses.push('app-transparent-btn');
  }

  if ( transparent ) {
    cssClasses.push('app-transparent-btn');
  }
  if ( cssClass ) {
    cssClasses.push(cssClass);
  }
    

  let html = null;
  if  ( type === 'button' ) {
    html = 
      <button disabled={disabled}  type="button"  className={ 'app-button-component'} onClick={handleOnClick}>
        {children}
      </button> 
  } 
    else if ( type == "link" ) {
      html = 
      <Link to={to} disabled={disabled} className={ 'app-button-component app-link-button-component'} >
        {children}
      </Link>

      if ( href == '_blank' ) {
        html =  <a className={ 'app-button-component app-link-button-component'} target="_blank" href={to}>
                  {children}
                </a>

        

      }
    }
    else if ( type == "submit" ){  
     html = 
        <button 
          className={ 'app-button-component app-button-submit-component'}  disabled={disabled} onClick={handleOnClick}>
          {children}
        </button>
    }
    return(
      <AppDiv cssClass={cssClasses.join(" ")}>
        {html}
      </AppDiv>
    )
  }
  