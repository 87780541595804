import  Http from "api/user/axios/axios";

export const login = async ( {data} ) => {
    
    return new Promise( async (resolve , reject) => {

            let o = {
                response:false,
                data:{},
                err:{}
            };
    
            try {
                const res = await Http.post("/token/login" , data);
                o.data = res.data;  
                o.response = true; 

                
                resolve(o);          
            } catch ( e ){
                console.log(`error ${JSON.stringify(e)}`)
                o.err = {
                    "response":false,
                    "statusCode":500,
                    "category":"err",
                    "mesagee":"SERVER_ERROR"
                };
                if ( e?.response?.data ) {
                    o.err =  {
                        "statusCode":e.response.data.statusCode,
                        "mesagee":e.response.data.mesagee
                    }
                }
                
                reject(o);
            }
           
    
        });
        

    
}

