import { getDataFromStorage,cleanAll } from '../localStorage/localStorage'
import i18n from "i18next";
 

export const logOut = () =>{
  cleanAll();
//   window.location.reload()
}
/**
 * hendleError 
 * main app hendle ajax error
*/
export const handleError = ( e )  => {
	console.log(e);
}


/**
 * handleInputChange 
 * main input  ajax error
*/
export const handleInputChange = (event) => {
	const target = event.target;
	const value = target.type === 'checkbox' ? target.checked : target.value;
	const name = target.name;
	return {
		name,
		value
	}
}

export const checkIfFormCanBySubmit = (fields) => {
  let valid = true;
  for (var i = 0; i < fields.length; i++) {
    if ( fields[i].valid === false ) {
      valid = false;
    }
  };
  return valid 
}



export const checkIfCurrentFieldValid = (fields , o , validationType) => {
  let valid =  false;
  const current = fields.find( (obj) => {
    return obj.name === o.name;
  })
  if ( validationType === 'simple' ) {
      valid  = simpleValidation( o.value );
  } else if ( validationType === 'email' ) {
      valid  = emailValidation( o.value );
  } else if ( validationType === 'password' ) {
      valid  = simpleValidation( o.value , 5  );
  } else if ( validationType === 'number' ) {
      valid  = numberValidation( o.value   );
  } 

  current.valid =   valid;
  current.val = o.value;
  return fields

}

// validation 

export const simpleValidation = ( val , maxLength = 2 ) => {
    if ( ( val != null &&  val.length < maxLength )  || val === '' || val == null || val == undefined ) {
        return false;
    } else {
        return true;
    }
}



export const passwordValidation = (val) => {

    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:

    return regxValidation({
        val,
        expression: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%#*?&]{8,}$/
    })
}  

export const emailValidation = (val) => {
    return regxValidation({
        val,
        expression: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/
    })
}  

export const numberValidation = (val) => {
    return regxValidation({
        val,
        expression: /^[0-9]+$/
    })
}  


export const phoneValidation = (val) => {
    return regxValidation({
        val,
        expression: /^[0-9]{9,10}$/
    })
}  


export const walletValidation = (val) => {
    
    return regxValidation({
        val,
        expression: /([13]|bc1)[A-HJ-NP-Za-km-z1-9]{27,34}/
    })
}


// regx validation 
export const  regxValidation = ( o ) => {

    if ( !simpleValidation(o.val) ) {
        return false;
    } else {
        if (o.expression.test(o.val)) {
            return true;
        } else {
            return false;
        }
    }
}

export const isUserLoggedIn = () => {
    return getDataFromStorage('app-token') != null ? true : false ;
}

export const authRedirect = () => {
    window.location.replace('/');
}



export  const numberFormat = (user_input) => {
    // alert(user_input);
    var n = user_input;
    // console.log(n);
    var filtered_number = n.toString().replace(/[^0-9]/gi, '');
    var length = filtered_number.length;
    var breakpoint = 1;
    var formated_number = '';
    let i = '';
    for(i = 1; i <= length; i++){
        if(breakpoint > 3){
            breakpoint = 1;
            formated_number = ',' + formated_number;
        }
        var next_letter = i + 1;
        formated_number = filtered_number.substring(length - i, length - (i - 1)) + formated_number; 

        breakpoint++;
    }

    return formated_number;
}

export const getCurrentLang = () => {
    return localStorage.getItem("i18nextLng");
}


export const getCurrentLangTextAlign = () => {

    const currentLang = getCurrentLang();
    let align = 'right';
    if ( currentLang === 'en' ) {
        align = 'left';
    }

    return align
}
