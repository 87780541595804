import {AppDiv} from '../AppDiv/AppDiv';
import "./AppRow.css";

export const AppRow = ( {cssClass, cols=1 ,  justifyContent = 'center' , justifyontent = 'space-between', children , size = 'lg'} ) => {
    


    const cssClasses = [];
    cssClasses.push('app-flex');
    cssClasses.push('app-flex-wrap');
    cssClasses.push('app-row-component');

    if ( size == 'sml' ) {
      cssClasses.push('app-row-component-sml');
    }
    
    cssClasses.push('app-justify-' + justifyContent);

    if ( justifyontent != '' ) {
      cssClasses.push('app-justify-' + justifyontent);

    }
    cssClasses.push('app-row-component-has-'  +cols+ '-cols'  );
    

    cssClasses.push(cssClass);

    return (
      <AppDiv cssClass={cssClasses.join(" ")} >
         {children}
      </AppDiv>
    )
}
  