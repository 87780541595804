import { AppRow } from 'components/AppRow/AppRow';
import { AppCol } from 'components/AppCol/AppCol';
import { AppTitle } from 'components/AppTitle/AppTitle';
import "./AppLoggedInTitle.css";

/**
  * App Container component
*/
export const AppLoggedInTitle = ( {title, cssClass} ) => {
    
    return(
        <AppRow justifycontent='left'>
            <AppCol>
                <AppTitle cssClass={'app-user-is-logged-in-title'} textAlign={'left'}>
                    {title}
                </AppTitle>
            </AppCol>
        </AppRow>
    )
}