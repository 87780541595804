import { Link ,useNavigate } from "react-router-dom";
import { AppDiv } from 'components/AppDiv/AppDiv';
import { AppSection } from 'components/AppSection/AppSection';
import { AppContainer } from 'components/AppContainer/AppContainer';
import { AppRow } from 'components/AppRow/AppRow';
import { AppCol } from 'components/AppCol/AppCol';



import {ReactComponent as AppLogOutIcon} from  "svgs/logout.svg";
 
import { AppButton } from "components/AppButton/AppButton";
import { AppPaths } from "components/AppMenu/AppPaths";

import "./AppTopMenu.css";



/**
  * App Container component
*/
export const AppTopMenu = ( cssClass ) => {
    const navigate = useNavigate();
    const logOut = () => {
        localStorage.clear();
        navigate("/login");
    }
    return(
        <AppSection cssClass={'app-menu-section app-dashbord-menu-section'}> 

            <AppContainer>
            
            <AppRow justifycontent={'end'} cssClass={"app-flex-wrap app-flexalign-center "}>
                
                    </AppRow>
                <AppRow justifycontent={'end'} cssClass={"app-flex-wrap app-flexalign-center "}>
                    <AppCol cssClass={'app-menu-section-action-div-col'}>
                        <AppDiv cssClass={'app-menu-section-action-div'}>
                           
                            <AppButton transparent={true} handleOnClick={logOut}>
                                <AppLogOutIcon />
                            </AppButton>
                          
                        </AppDiv>
                        
                    </AppCol>
                
                </AppRow>
                
            </AppContainer>
        </AppSection>
    )
}