/*
	App routes files
*/
import React, { Suspense, lazy } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Switch,
  } from "react-router-dom";
import { isUserLoggedIn } from './utility/utility'
import Login from 'pages/loginBank/login'

import Bitspace from 'pages/dashboard/bitspace/page';


import { AppScrollToTop } from 'components/AppScrollToTop';
 
// import App from './App';


// pages

// lazy load components
// const Support = React.lazy(() => import('./pages/Support/page'));
// const Home = React.lazy(() => import('./pages/home/page'));







// auth routes
const PrivateRoute = ({ isLoggedIn, ...props }) => {
	// return isLoggedIn ? <Route { ...props } /> : <Redirect to="/login" />
}

/*
 * Defing client route
 * 
*/

const AppLoader = () => {
	return(
		<div>Loading...</div>
	)
}
export const AppRoutes = () => {
	const loggedIn = isUserLoggedIn();
	return(
		<BrowserRouter>
			<AppScrollToTop />
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path={"/cutomers"} element={<Bitspace />} />
			</Routes>
		</BrowserRouter>
	)
}
