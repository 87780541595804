import "animate.css/animate.min.css";
import './App.css';
import { AppRoutes } from './routes'
import { useTranslation ,  getI18n } from 'react-i18next';
import { useEffect } from 'react';
function App() {
 

  return (
    <div className="App app-modern-desgin">
      <AppRoutes  />
    </div>
  );
}

export default App;
