import "./AppSection.css";
export const AppSection = ({cssClass , children, removePadding = '' , backgroundImage= ''}) => {
    const cssClasses = [];
    cssClasses.push('app-section-component');

    if ( removePadding ) {
        cssClasses.push('app-remove-padding-' + removePadding);
    }
    cssClasses.push(cssClass);

    let style = {};

    if ( backgroundImage != '' ) {
        style =  {
            backgroundImage:  `url("${backgroundImage}")`
        } 
    }
    

    return(
        <section style={style}  className={cssClasses.join(" ")}>
            {children}
        </section>
    )
}
