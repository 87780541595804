export const AppPaths = {
    USERHOME: "/user/home",
    LOGIN: "/login",
    SUPPORT: "/support",
    ABOUT: "/about",
    DASHBOARD: "/dashboard",
    FORGOTPASSWORD: "/forgotPassword",
    FORGOTPASSWORDCONFIRM: "/forgotPasswordConfirm",
    SUMSUBFORM: "/register",
    REGISTER: "/register",
    VERIFYACCOUNT: "/verify",
    CHOOSEPASSWORD: "/choosePassword",
    THANKYOU: "/thankyou",
    BITSPACE: "/customers",
    CHAT: "/chat",
    WALLET: "/wallet",
    SUPPORT: "/support",
    PROFILE: "/profile",
    LOAN: '/loan',
    DOCS: '/docs',
    CONTACT: "/contact"
}