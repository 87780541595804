import {AppDiv} from '../AppDiv/AppDiv';
import "./AppContainer.css";

/**
  * App Container component
*/
export const AppContainer = ( {cssClass , children , fullWidth} ) => {
    const cssClasses = [];
    cssClasses.push('app-flex app-flex-wrap');
    cssClasses.push('app-container-component');

    if ( fullWidth == true ) {
      cssClasses.push('app-container-full-width-component');
    }
    cssClasses.push(cssClass);

    return(
      <AppDiv cssClass={cssClasses.join(" ")} >
        {children}
      </AppDiv>
    )
}